/*
 * The syntax light mode typography colors
 */

@mixin light-scheme {
  /* Common */
  --body-bg: #fafafa;
  --mask-bg: #c1c3c5;
  --main-wrapper-bg: white;
  --main-border-color: #f3f3f3;
  --btn-border-color: #e9ecef;
  --text-color: #333333;
  --blockquote-border-color: #eee;
  --blockquote-text-color: #9a9a9a;
  --link-color: #2a408e;
  --link-underline-color: #dee2e6;
  --text-muted-color: gray;
  --tb-odd-bg: #fbfcfd;
  --tb-border-color: #eaeaea;
  --button-bg: #fff;
  --btn-backtotop-color: #686868;
  --btn-backtotop-border-color: #f1f1f1;
  --btn-box-shadow: #eaeaea;
  --checkbox-color: #c5c5c5;
  --checkbox-checked-color: #07a8f7;

  /* Sidebar */
  --sidebar-bg: radial-gradient(
    circle,
    rgba(42, 30, 107, 1) 0%,
    rgba(35, 37, 46, 1) 100%);
  --nav-cursor-color: #fcfcfc;

  /* Topbar */
  --topbar-wrapper-bg: white;
  --topbar-text-color: rgb(78, 78, 78);
  --search-wrapper-bg: #f5f5f5;
  --search-tag-bg: #f8f9fa;
  --search-icon-color: #c2c6cc;
  --input-focus-border-color: var(--btn-border-color);

  /* Home page */
  --post-list-text-color: dimgray;
  --btn-patinator-text-color: #555555;
  --btn-paginator-hover-color: #e9ecef;
  --btn-active-bg: #2a408e;
  --btn-active-border-color: #007bff;
  --btn-text-color: #f8f8f8;
  --btn-paginator-border-color: #f1f1f1;
  --btn-paginator-shadow: #4b92d2;
  --pin-bg: #f5f5f5;
  --pin-color: #999fa4;

  /* Posts */
  --btn-share-hover-color: var(--link-color);
  --card-border-color: #f1f1f1;
  --card-box-shadow: rgba(234, 234, 234, 0.7686274509803922);
  --label-color: #616161;
  --relate-post-date: rgba(30, 55, 70, 0.4);
  --tag-bg: rgba(0, 0, 0, 0.075);
  --tag-border: #dee2e6;
  --tag-shadow: var(--btn-border-color);
  --tag-hover: rgb(222, 226, 230);
  --categories-hover-bg: var(--btn-border-color);
  --dash-color: silver;

  /* Archive */
  --timeline-color: rgba(0, 0, 0, 0.075);
  --timeline-node-bg: #c2c6cc;
  --timeline-year-dot-color: #ffffff;

  /* Footer */
  --footer-bg-color: #ffffff;
  --footnote-target-bg: lightcyan;
  --footer-link: #424242;
} // light-scheme
